@media (max-width: 991.98px)  {
    .alignElementsSpaceAroundAndCenter{
        justify-content: center;
    }
    .alignElementsStartAndCenter {
        justify-content: center;
    }
    .alignElementsCenterAndEnd {
        justify-content: center;
    }
}

@media (min-width: 992px)  {
    .alignElementsSpaceAroundAndCenter{
        justify-content: space-around;
    }
    .alignElementsStartAndCenter{
        justify-content: start;
    }
    .alignElementsCenterAndEnd{
        justify-content: end;
    }
}

.buttonPrimary {
    color: #fff;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
    display: block;
    background-color: #0053a6 !important;
    text-align: center;
}

.buttonPrimary:hover {
    color: #fff;
    background-color: #036 !important;
}

.inputWithErrorMargin {
    margin: 12px 0px;
}

.clickableLabel>label{
    pointer-events: none;
}

.cookiebar {
    max-width: none;   
}

.cookiebar .cookiebar-btn:not(.cookiebar-confirm) {
    margin: 0px 5px;
    padding: 10px;
    min-width: 102px;
}

.cookiebar p {
    width: 100%;
}

@media (min-width: 767.99px) {
    .cookiebar .cookiebar-buttons {
        width: 25%;
        place-self: center;
    }
}

@media (max-width: 767.98px) {
    .cookiebar-buttons {
        text-align: center !important;
        width: 100%;
    }
}

.accettaButton {
    background-color: #17ad5a !important;
    margin-top: 5px !important;
}

.hideCookieAlert {
    display: none !important;
}

.cookiebar a {
    color: white;
}

.breadcrumbTopMargin .breadcrumb {
    margin: 0px !important;
}

.activeBreadCrumb {
    text-decoration: underline;
}